<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <h1>WordCloud</h1>
        <br />
        <!-- Refund Policy -->
        <el-collapse>
          <el-collapse-item :title="$t('Refund Policy')">
            <el-card>
              <li>All purchases are non-refundable. Please consider your decisions carefully before making a purchase.</li>
              <li>In special circumstances, such as when the service is unavailable or there is a major error, we may consider refund requests at our discretion.</li>
              <li>Refund requests must be made within 7 days of purchase. To request a refund, please contact our customer support team.</li>
            </el-card>
          </el-collapse-item>
        </el-collapse>

        <!-- Terms of Service -->
        <el-collapse>
          <el-collapse-item :title="$t('Terms of Service')">
            <el-card>
              <li>We will make every effort to provide you with the best possible service.</li>
              <li>You agree to comply with our terms of service and use our services for lawful purposes.</li>
              <li>We reserve the right to change the terms of service at any time. If you violate the terms of service, we may suspend or terminate your service.</li>
            </el-card>
          </el-collapse-item>
        </el-collapse>

        <!-- Privacy Policy -->
        <el-collapse>
          <el-collapse-item :title="$t('Privacy Policy')">
            <el-card>
              <li>We will not collect any of your personal information, such as your name, address, or email address.</li>
              <li>We may collect some anonymous data, such as your IP address and browser type.</li>
              <li>We use this data to improve our services and provide you with a better experience.</li>
              <li>We will not share your personal information with third parties.</li>
            </el-card>
          </el-collapse-item>
        </el-collapse>

        <!-- <a href="/">Return</a> -->
        <p>
          Copyright © emperinter
        </p>
      </div>
    </div>
  </div>
</template>
dd
<script>
export default {
  name: 'ServiceComponent'
}
</script>

<style scoped>
li{
  text-align: left;
}
</style>