// config.js

// Paddle BearToken
export const config = {
  token: 'c17913d0a425e8db7274b3188d09bfd0c381e17f82207db908'
};


export const productDaysMap = {
  "DailyPlan": 1,
  "MonthlyVIP": 32,
  "QuartelyVIP": 96,
  "AnnualVIP": 366
};