<template>
  <div class="common-layout">
    <!-- Data -->
    <el-row :gutter="10">
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <div>
          <p>
            <el-button type="warning" @click="priceVisible = true">{{ $t('Price') }}</el-button>
          </p>
          <h2>{{ $t('Data') }}</h2>
          <el-input v-model="key" :placeholder="$t('Enter key')" clearable></el-input>
          <el-input v-model="value" :placeholder="$t('Enter value')" clearable></el-input>
          <!-- <el-button type="primary" @click="addData" round :disabled="!isInputValid">{{ $t('Add Data') }}</el-button> -->
          <el-button type="primary" @click="addData" round>{{ $t('Add Data') }}</el-button>
        </div>
        <hr>
        <div>
          <el-scrollbar max-height="50vh">
            <el-table :data="chartData" style="width: 100%">
              <el-table-column prop="name" :label="$t('Name')" />
              <el-table-column prop="value" :label="$t('Value')" />
              <el-table-column :label="$t('Operations')" width="180">
                <template #default="scope">
                  <el-button type="danger" @click="deleteData(scope.$index)">{{ $t('Delete') }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </div>
        <hr>
        <el-select v-model="selectedLang" @change="changeLang" placeholder="Select Language">
          <el-option v-for="(item, index) in Object.values($i18n.messages.tag_label)" :key="index" :label="item"
            :value="$i18n.messages.label_tag[item]"></el-option>
        </el-select>
        <p>
          <el-button plain @click="serviceVisible = true">{{ $t('Service') }}</el-button>
        </p>
        <p>
          Copyright © emperinter
        </p>

      </el-col>

      <!-- WordCloud -->
      <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
        <div style="display: flex; background-color: black; justify-content: center; align-items: center; height:98vh">
          <div>
            <div v-if="isSubscribed" ref="chart"
              :style="{ backgroundColor: backgroundColor, width: imageWidth / 2 + 'px', height: imageHeight / 2 + 'px' }">
            </div>
            <div v-else>
              <img :src="imageUrl"
                :style="{ width: imageWidth + 'px', height: imageHeight + 'px', backgroundColor: backgroundColor }">
            </div>
          </div>
        </div>
      </el-col>

      <!-- Options -->
      <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
        <el-scrollbar height="98vh">
          <div>
            <h1>{{ $t('Options') }}</h1>
            <!-- <el-button type="danger" @click="generateData" round>
              {{ $t('Generate') }}<el-icon class="el-icon--right">
                <Refresh />
              </el-icon>
            </el-button>
            <br> -->
            <el-button type="success" @click="exportLayout" round>
              {{ $t('OutPut') }}<el-icon class="el-icon--right">
                <Upload />
              </el-icon>
            </el-button>
            <el-divider></el-divider>

            <h2>{{ $t('Mask') }}</h2>
            <el-button type="warning" @click="maskVisible = true">{{ $t('Masks') }}</el-button>
            
            <el-divider></el-divider>
            <h2>{{ $t('Image') }}</h2>
            <p>{{ $t('imageWidth') }}</p>
            <el-input-number v-model="imageWidth" :min="10" :max="10000" :label="$t('imageWidth')"></el-input-number>
            <p>{{ $t('imageHeight') }}</p>
            <el-input-number v-model="imageHeight" :min="10" :max="10000" :label="$t('imageHeight')"></el-input-number>
            <p>{{ $t('backgroundColor') }}</p>
            
            <el-color-picker v-model="backgroundColor" show-alpha :predefine="predefineColors"
              :label="$t('Background Color')" size="large"></el-color-picker>
            <el-divider></el-divider>

            <h2>{{ $t('FontSet') }}</h2>
            <p>{{ $t('minSize') }}</p>
            <el-input-number v-model="minSize" :min="10" :max="10000" :label="$t('minSize')"></el-input-number>
            <p>{{ $t('maxSize') }}</p>
            <el-input-number v-model="maxSize" :min="10" :max="10000" :label="$t('maxSize')"></el-input-number>
            <p>{{ $t('Font') }}</p>
            <el-select v-model="selectedFont" :placeholder="$t('SelectFont')">
              <el-option
                v-for="font in fontOptions"
                :key="font.value"
                :label="font.label"
                :value="font.value"
              ></el-option>
            </el-select>
            <el-divider></el-divider>

            <!-- <h2>{{ $t('Shape') }}</h2>
            <el-button @click="changeShape('circle')">{{ $t('Circle') }}</el-button>
            <el-button @click="changeShape('triangle')">{{ $t('Triangle') }}</el-button> -->

          </div>
        </el-scrollbar>
      </el-col>
    </el-row>

    <el-dialog v-model="maskVisible" :title="$t('Masks')" width="500" :before-close="handleClose">
      <div v-if="this.hasPurchased()">
        <SelectMask @mask-selected="handleMaskSelected" />
      </div>
      <div v-else>
        <el-tabs :tab-position="tabPosition">
          <el-tab-pane :label="$t('Plan')">
            <PricePage />
          </el-tab-pane>
          <el-tab-pane :label="$t('Account')">
            <Account />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>

    <el-dialog v-model="priceVisible" :title="$t('')" width="500" :before-close="handleClose">
      <el-tabs :tab-position="tabPosition" label-width="250px">
        <el-tab-pane :label="$t('Plan')">
          <PricePage />
        </el-tab-pane>
        <span></span>
        <el-tab-pane :label="$t('Account')">
          <Account />
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog v-model="serviceVisible" :title="$t('Service')" width="500" :before-close="handleClose">
      <Service />
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { ElInput, ElButton, ElInputNumber, ElColorPicker, ElNotification } from 'element-plus';
import { Upload } from '@element-plus/icons-vue'
import SelectMask from './SelectMask.vue';
import { ref } from 'vue'
import html2canvas from 'html2canvas';
import PricePage from './PricePage.vue';
import Service from './Service.vue';
import Account from './Account.vue';
import dogImg from '../assets/love.png';

export default {
  name: 'HomePage',
  components: {
    ElInput,
    ElButton,
    ElInputNumber,
    ElColorPicker,
    SelectMask,
    Upload,
    PricePage,
    Service,
    Account
  },
  setup() {
    const predefineColors = ref([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsv(51, 100, 98)',
      'hsva(120, 40, 94, 0.5)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
    ]);

    return {
      predefineColors
    }
  },
  data() {
    return {
      chartData: [], // 初始化一批数据
      key: '',
      value: '',
      editingIndex: -1,
      backgroundColor: '#FFFFFF',
      maskVisible: false,
      imageHeight: 1024,
      imageWidth: 1024,
      priceVisible: false,
      serviceVisible: false,
      selectedLang: null,
      selectedMask: '',
      isSubscribed: true,
      minSize: 10,
      maxSize: 1000,
      selectedFont: '',
      fontOptions: [
        { label: 'Inter', value: 'Inter, sans-serif' },
        { label: 'Helvetica Neue', value: '\'Helvetica Neue\', sans-serif' },
        { label: 'Helvetica', value: 'Helvetica, sans-serif' },
        { label: 'PingFang SC', value: '\'PingFang SC\', sans-serif' },
        { label: 'Hiragino Sans GB', value: '\'Hiragino Sans GB\', sans-serif' },
        { label: 'Microsoft YaHei', value: '\'Microsoft YaHei\', sans-serif' },
        { label: '微软雅黑', value: '微软雅黑, sans-serif' },
        { label: 'Arial', value: 'Arial, sans-serif' }
      ]
    };
  },
  created() {
    // 监听 imageHeight 变化
    this.$watch('imageHeight', (newValue, oldValue) => {
      console.log('imageHeight 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    // 监听 imageWidth 变化
    this.$watch('imageWidth', (newValue, oldValue) => {
      console.log('imageWidth 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('minSize', (newValue, oldValue) => {
      console.log('minSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('maxSize', (newValue, oldValue) => {
      console.log('maxSize 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
    this.$watch('chartData', (newValue, oldValue) => {
      console.log('chartData 变化了：', newValue, oldValue);
      setTimeout(() => {
        this.updateChart();
      }, 500); // 在这里设置延时时间，单位为毫秒
    });
  },
  computed: {
    isInputValid() {    
      console.log("isInputValid",this.key.trim() !== '' && this.value.trim() !== '');
      return this.key.trim() !== '' && this.value.trim() !== '';
    }
  },
  mounted() {
    this.selectedLang = this.$i18n.locale;
    this.hasPurchased();
    this.generateData();
    this.initChart();
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
    },
    initChart() {
      const chart = echarts.init(this.$refs.chart);
      var option = {
        sizeRange: [this.minSize, this.maxSize],
        rotationRange: [-90, 90],
        rotationStep: 1,
        gridSize: 1,
        drawOutOfBound: false,
        shrinkToFit: true,
        keepAspect: false,
        layoutAnimation: true,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        right: 0,
        bottom: 0,
        series: [{
          type: 'wordCloud',
          shape: 'circle',
          textStyle: {
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            color: function () {
              return 'rgb(' + [
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160),
                Math.round(Math.random() * 160)
              ].join(',') + ')';
            }
          },
          data: this.chartData
        }]
      };

      var maskImage = new Image();
      maskImage.onload = function () {
        option.series[0].maskImage = maskImage;
        chart.setOption(option);
      };
      // maskImage.src = "https://wordcloudmaster-preimages.emperinter.com/dog";
      maskImage.src = dogImg;
    },
    Notify() {
      ElNotification({
        title: this.$t("Warning"),
        message: this.$t("InvalidInput"),
        position: 'top-left',
        type: 'warning'
      })
    },
    hasPurchased() {
      const purchaseStatus = localStorage.getItem("purchaseStatus");
      const expirationTime = localStorage.getItem("expirationTime");
      const hasPurchased = purchaseStatus === "purchased" && expirationTime && new Date() < new Date(expirationTime);
      return hasPurchased;
    },
    addData() {
      if (this.hasPurchased()) {
        if (this.key && this.value) {
          if (this.editingIndex > -1) {
            // 编辑已有数据
            this.chartData[this.editingIndex] = { name: this.key, value: this.value };
            this.editingIndex = -1;
          } else {
            // 添加新数据
            this.chartData.push({ name: this.key, value: this.value });
          }
          this.key = '';
          this.value = '';
          this.updateChart();
        }else{
          this.Notify();
        }
      } else {
        this.priceVisible = true;
      }
    },
    generateData() {
      // 初始化一批数据
      this.chartData = [
        { name: 'WordCloud', value: 500 },
        { name: 'سحابة الكلمات', value: 100 },
        { name: 'Облак на думи', value: 100 },
        { name: 'Núvol de paraules', value: 100 },
        { name: 'Oblak riječi', value: 100 },
        { name: 'Slovní mrak', value: 100 },
        { name: 'Ordmoln', value: 100 },
        { name: 'Woordenwolk', value: 100 },
        { name: 'Sõnapilv', value: 100 },
        { name: 'Sanapilvi', value: 100 },
        { name: 'Nuage de mots', value: 100 },
        { name: 'Wortwolke', value: 100 },
        { name: 'σύννεφο λέξεων', value: 100 },
        { name: 'ענן מילים', value: 100 },
        { name: 'शब्दांश', value: 100 },
        { name: 'Szófelhő', value: 100 },
        { name: 'Awan Kata', value: 100 },
        { name: 'Parole di nuvola', value: 100 },
        { name: 'ワードクラウド', value: 100 },
        { name: '단어 구름', value: 100 },
        { name: 'Vārdu mākoņa', value: 100 },
        { name: 'Žodžių debesys', value: 100 },
        { name: 'Awan Kata', value: 200 },
        { name: 'Ordsky', value: 200 },
        { name: 'Słowo chmura', value: 200 },
        { name: 'Nuvem de palavras', value: 200 },
        { name: 'Nor de cuvinte', value: 200 },
        { name: 'Облако слов', value: 200 },
        { name: '词云词', value: 200 },
        { name: 'Slovný mrak', value: 300 },
        { name: 'Oblak besed', value: 300 },
        { name: 'Nube de palabras', value: 300 },
        { name: 'Ordmoln', value: 330 },
        { name: 'Ord Moln', value: 340 },
        { name: 'Mây từ', value: 350 },
        { name: 'Bulut kelime', value: 360 },
        { name: 'Хмара слів', value: 370 },
        { name: 'Đám mây từ', value: 380 }
      ];
      this.updateChart();
    },
    changeShape(shape) {
      // 修改词云图的形状
      this.chart.setOption({
        series: [{
          type: 'wordCloud',
          shape: shape
        }]
      });
      this.updateChart();
    },
    updateChart() {
      if (this.hasPurchased()) {
        const chart = echarts.init(this.$refs.chart);
        const chartData = this.chartData;
        const selectedMask = this.selectedMask;
        const minSize = this.minSize;
        const maxSize = this.maxSize;
        const SelectFont = this.selectedFont;
        const maskImage = selectedMask ? new Image() : null;
        // 如果有选定的口罩数据，则加载口罩图像
        if (selectedMask) {
          maskImage.onload = function () {
            chart.setOption({
              series: [{
                sizeRange: [minSize, maxSize],
                rotationRange: [-90, 90],
                rotationStep: 1,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                right: 0,
                bottom: 0,
                gridSize: 1,
                drawOutOfBound: false,
                shrinkToFit: true,
                layoutAnimation: true,
                keepAspect: false,
                maskImage: maskImage,
                type: 'wordCloud',
                textStyle: {
                  fontFamily: SelectFont,
                  fontWeight: 'bold',
                  color: function () {
                    return 'rgb(' + [
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160),
                      Math.round(Math.random() * 160)
                    ].join(',') + ')';
                  }
                },
                data: chartData
              }]
            });
          };
          maskImage.src = selectedMask;
          chart.resize();
        } else {
          const maskImage =  new Image();
          var option = {
            series: [{
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              right: 0,
              bottom: 0,
              maskImage: null,
              type: 'wordCloud',
              fontFamily: SelectFont,
              fontWeight: 'bold',
              textStyle: {
                color: function () {
                  return 'rgb(' + [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                  ].join(',') + ')';
                }
              },
              data: chartData
            }]
          };
          maskImage.onload = function () {
            option.series[0].maskImage = maskImage;
            chart.setOption(option);
          };
          // maskImage.src = "https://wordcloudmaster-preimages.emperinter.com/dog";
          maskImage.src = dogImg;
          chart.resize();
        }
      } else {
        this.priceVisible = true;
      }
    },
    editData(index) {
      // 编辑数据
      this.editingIndex = index;
      this.key = this.chartData[index].name;
      this.value = this.chartData[index].value;
    },
    deleteData(index) {
      // 删除数据
      this.chartData.splice(index, 1);
      this.updateChart();
    },
    handleMaskSelected(mask) {
      // 接收口罩选择事件，并更新选定的口罩数据
      this.selectedMask = mask;
      this.updateChart();
      this.maskVisible = false;
    },
    exportLayout() {
      const chartElement = this.$refs.chart;
      // 使用HTML2Canvas将DOM元素转换为图片
      html2canvas(chartElement, {
        backgroundColor: this.backgroundColor, // 设置背景颜色
        logging: false // 可选，禁用HTML2Canvas日志输出
      }).then(canvas => {
        // 将Canvas转换为图片URL
        const dataURL = canvas.toDataURL('image/png');
        // 创建一个下载链接并设置图片URL
        const a = document.createElement('a');
        a.href = dataURL;
        const date = new Date();
        const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        a.download = `wordcloud_${dateString}.png`;
        // 模拟点击下载链接
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }
};
</script>

<style>
.common-layout {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 100%;
}

.el-alert {
  margin: 20px 0 0;
}
.el-alert:first-child {
  margin: 0;
}

.dt {
  /* background-color: gray; */
}

.opt {
  /* background-color: gray; */
}
</style>
