// router.js

import { createRouter, createWebHistory } from 'vue-router';
import Service from './components/Service.vue';
import PricePage from './components/PricePage.vue'
// 导入你的组件
import HomePage from './components/HomePage.vue';
import AboutPage from './components/About.vue';
import SelectMask from './components/SelectMask.vue'
import AccountPage from './components/Account.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage
  },
  {
    path: '/service',
    name: 'service',
    component: Service
  },
  {
    path: '/price',
    name: 'price',
    component: PricePage
  },
  {
    path: '/mask',
    name: 'mask',
    component: SelectMask
  },
  {
    path: '/account',
    name: 'account',
    component: AccountPage
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
