<template>
  <div id="app">
    <router-view></router-view> <!-- 这里是动态渲染的组件 -->
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
/* 可以添加一些全局的样式 */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #263747;
}
</style>
