<template>
  <div align="center">
    <div v-if="transactionInfo">
      <div v-if="isOneTime">
        <img :src="VIP" alt="VIP Image">
        <h1>{{ translatedProductName }}</h1> <!-- 使用 translatedProductName 替换 productName -->
        <p>{{ expirationTime }}</p>
      </div>
      <div v-if="isSubscription">
        <img :src="VIP" alt="VIP Image">
        <h1>{{ translatedProductName }}</h1> <!-- 使用 translatedProductName 替换 productName -->
        <p>{{ expirationTime }}</p>
        <!-- <p>Subscription : {{ current_billing_period_start_at }} - {{ current_billing_period_end_at }}</p> -->
        <span></span>
        <a :href="cancel_url" target="_blank">
          <el-button type="danger">{{ $t('Cancel') }}</el-button>
        </a>
      </div>
      <div v-else>
        <img :src="NOVIP" alt="NO VIP Image">
        <h1>{{ $t('NOVIP') }}</h1> <!-- 使用 translatedProductName 替换 productName -->
      </div>
    </div>
    <div v-else>
      <img :src="NOVIP" alt="NO VIP Image">
      <h1>{{ $t('NOVIP') }}</h1> <!-- 使用 translatedProductName 替换 productName -->
    </div>
    <br>
    <el-form :model="formData" ref="emailForm" :rules="rules" inline>
      <el-form-item prop="email">
        <el-input v-model="email" :placeholder="$t('EmailRequired')" type="email"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="validateAndGetData">{{ $t('Restore') }}</el-button>
      </el-form-item>
    </el-form>

    <p>
      Copyright © emperinter
    </p>
  </div>
</template>

<script>
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { productDaysMap, config } from '@/config'; // 修改路径以匹配你的实际情况
import VIP from '@/assets/vip.png';
import NOVIP from '@/assets/novip.png';

export default {
  name: 'AccountPage',
  components: {
  },
  data() {
    return {
      email: '',
      userInfo: null,
      transactionInfo: null,
      isOneTime: false,
      productName: '',
      isSubscription: false,
      expirationTime: null,
      current_billing_period_start_at: "",
      current_billing_period_end_at: "",
      update_payment_method_url: "",
      cancel_url: "",
      VIP: VIP,
      NOVIP: NOVIP,
      formData: { email: '' },
      rules: {
        email: [
          { 
            required: true, 
            message: this.$t('EmailRequired'),
            trigger: 'blur' },
          { 
            type: 'email', 
            message: this.$t('VaildEmail'),
            trigger: ['blur', 'change'] }
        ]
      }
    };
  },
  watch: {
    email(newValue) {
      this.formData.email = newValue;
    }
  },
  computed: {
    translatedProductName() {
      return this.$t(this.productName); // 获取动态 productName 的翻译
    }
  },
  mounted() {
    this.expirationTime = localStorage.getItem("expirationTime");
    this.email = localStorage.getItem("email");
    if (this.expirationTime && new Date() < new Date(this.expirationTime)) {
      this.isSubscribed = true;
      this.subscriptionExpiration = new Date(this.expirationTime).toLocaleString();
    } else {
      this.isSubscribed = false;
      this.subscriptionExpiration = new Date().toLocaleString();
      localStorage.clear;
    }
  },
  methods: {
    async validateAndGetData() {
      try {
        await this.$refs.emailForm.validate(); // 进行邮箱格式验证
        if (this.email.includes("@")){
          await this.getUserInfo(); // 验证通过后请求接口数据
        }
      } catch (error) {
        console.error('Error occurred:', error);
        ElNotification({
          title: this.$t("Warning"),
          message: this.$t("InvaildUser"),
          position: 'top-left',
          type: 'warning'
        })
      }
    },
    async getUserInfo() {
      try {
        const userResponse = await axios.get(`https://paddle.emperinter.com/get_paddle_data/customer?email=${this.email}&format=json`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${config.token}` // 使用导入的token
          }
        });
        console.log(userResponse);
        this.userInfo = userResponse.data.data[0];
        if (this.userInfo) {
          localStorage.setItem("email", this.userInfo.email);

          // Querying transaction information
          const transactionResponse = await axios.get(`https://paddle.emperinter.com/get_paddle_data/transactions`, {
            params: {
              customer_id: this.userInfo.id,
              status: 'completed'
            },
            headers: {
              'Accept': 'application/json',
              'Authorization': `Bearer ${config.token}` // 使用导入的token
            }
          });
          this.transactionInfo = transactionResponse.data.data;
          this.isOneTime = this.transactionInfo.some(transaction => transaction.subscription_id == null);
          if (this.isOneTime) {
            const oneTimeBilledAt = new Date(this.transactionInfo.find(transaction => transaction.subscription_id == null).billed_at);
            this.productName = this.transactionInfo.find(transaction => transaction.subscription_id == null).items[0].price.name;
            // console.log(oneTimeBilledAt);
            // console.log(this.productName);
            const expirationTime = new Date(oneTimeBilledAt);
            expirationTime.setDate(expirationTime.getDate() + productDaysMap[this.productName]); // 增加相应的天数
            if (expirationTime && new Date() < new Date(expirationTime)){
              localStorage.setItem("expirationTime", expirationTime.toISOString()); // 转换为字符串并存储
              localStorage.setItem("purchaseStatus", "purchased");
            }else{
              localStorage.setItem("purchaseStatus", "expired");
            }
            // console.log(expirationTime);
          }

          // Checking if the user has a subscription
          this.isSubscription = this.transactionInfo.some(transaction => transaction.subscription_id !== null);

          // console.log(this.isSubscription);

          // If the user has a subscription, get subscription information
          if (this.isSubscription) {
            this.isOneTime = false;
            const subscriptionId = this.transactionInfo.find(transaction => transaction.subscription_id !== null).subscription_id;
            // console.log(subscriptionId);
            const subscriptionResponse = await axios.get(`https://paddle.emperinter.com/get_paddle_data/subscriptions?sub_id=${subscriptionId}&format=json`, {
              headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${config.token}` // 使用导入的token
              }
            });

            // ProductName
            this.productName = subscriptionResponse.data.data.items[0].price.name;

            // Time
            this.current_billing_period_start_at = subscriptionResponse.data.data.current_billing_period.starts_at;
            this.current_billing_period_end_at = subscriptionResponse.data.data.current_billing_period.ends_at;
            // console.log(this.current_billing_period_end_at);
            const expirationTime = new Date(this.current_billing_period_end_at);
            expirationTime.setDate(expirationTime.getDate());
            localStorage.setItem("expirationTime", expirationTime.toISOString());
            if (expirationTime && new Date() < new Date(expirationTime)){
              localStorage.setItem("expirationTime", expirationTime.toISOString()); // 转换为字符串并存储
              localStorage.setItem("purchaseStatus", "purchased");
            }else{
              localStorage.setItem("purchaseStatus", "expired");
            }
            // console.log(expirationTime);

            // Manage
            const management_urls = subscriptionResponse.data.data.management_urls;
            // console.log(management_urls);
            this.cancel_url = management_urls.cancel;
            this.update_payment_method_url = management_urls.update_payment_method;
            // console.log(this.cancel_url);
          }
        }else{
          ElNotification({
            title: this.$t("Warning"),
            message: this.$t("InvaildUser"),
            position: 'top-left',
            type: 'warning'
          })
        }

      } catch (error) {
        console.error('Error occurred:', error);
      }
    }

  }
};
</script>


<style scoped>
img {
  /* 设置图像宽度 */
  width: 100px;
  /* 设置图像高度 */
  height: 100px;
  /* 设置图像居中 */
  margin: 0 auto;
  /* 设置图像垂直居中 */
  display: block;
}
</style>
